import React from 'react';
import { clsx } from 'clsx';
import styles from './Switch.module.scss';

type SwitchProps = {
    name?: string;
    label?: React.ReactNode | React.ReactNode[] | null;
    leftLabel?: React.ReactNode | React.ReactNode[] | null;
    value?: boolean;
    size?: 's' | 'm' | 'l';
    onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export const Switch: React.FC<SwitchProps> = ({
    name,
    label = null,
    leftLabel = null,
    value = false,
    size = 'm',
    onChange,
}: SwitchProps): JSX.Element => {
    const wrapClasses = clsx(styles.wrap, styles[size]);
    const iconClasses = clsx(styles.icon, styles[size], {
        [styles.on]: value,
    });
    const labelClasses = clsx(styles.label, styles[size], {
        [styles.on]: value,
    });
    const leftLabelClasses = clsx(styles.leftLabel, styles[size], {
        [styles.on]: !value,
    });

    return (
        <div className={wrapClasses}>
            {leftLabel && (
                <label className={leftLabelClasses}>{leftLabel}</label>
            )}
            <div className={iconClasses}>
                <span />
            </div>
            {label && <label className={labelClasses}>{label}</label>}
            <input
                type="checkbox"
                name={name}
                checked={value}
                onChange={onChange}
            />
        </div>
    );
};
