import { getUserFeatureFlags } from './feature-flag-definition';
import { Environment } from '../Environment';

export class FeatureFlagService {
    public static getEnabledFeatureFlags(): string[] {
        return JSON.parse(
            localStorage.getItem('hustleEnabledFeatureFlags') || '[]'
        );
    }

    private static setEnabledFeatureFlags(flags: string[]) {
        localStorage.setItem(
            'hustleEnabledFeatureFlags',
            JSON.stringify(flags)
        );
    }

    static updateUserFlags = (userId: any) => {
        let flags = getUserFeatureFlags(userId);
        if (Environment.isLocalhost && flags.length === 0) {
            flags = getUserFeatureFlags('localhost');
        }
        FeatureFlagService.setEnabledFeatureFlags(flags);
    };
}
