import Container from '../layout/atoms/Container';
import Button from '../layout/atoms/Button';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Api from '../api';
import { UserState } from '../store/userSlice';
import { useNavigate } from 'react-router';
import TextEditable from '../layout/atoms/TextEditable';

export const AutomationCreatePage = () => {
    const userState: UserState = useSelector((state: any) => state.user);
    const [saving, setSaving] = useState(false);
    const [selectedDay, setSelectedDay] = useState<number | null>(null);

    const [automationName, setAutomationName] = useState<string>('');
    const [automationNamePlaceholder, setAutomationNamePlaceholder] =
        useState<string>('');

    const [taskName, setTaskName] = useState<string>('a task');

    const navigate = useNavigate();

    const updateAutomationNamePlaceholder = (
        task: string,
        day: number | null
    ) => {
        if (day === null) {
            setAutomationNamePlaceholder('');
            return;
        }
        setAutomationNamePlaceholder(
            `Monthly on ${day}th add ${task} in Execute`
        );
    };

    const selectDay = (day: number) => {
        setSelectedDay(day);
        updateAutomationNamePlaceholder(taskName, day);
    };

    const handleAutomationNameChange = (event: any) => {
        if (event.target.value.endsWith('\n')) {
            return;
        }
        setAutomationName(event.target.value);
    };

    const handleTaskNameChange = (event: any) => {
        if (event.target.value.endsWith('\n')) {
            return;
        }
        setTaskName(event.target.value);
        updateAutomationNamePlaceholder(event.target.value, selectedDay);
    };

    const save = () => {
        if (selectedDay === null || saving) {
            return;
        }
        setSaving(true);

        const name = automationName ? automationName.trim() : undefined;

        const steps: {
            type: 'TASK';
            payload: any;
        }[] = [
            {
                type: 'TASK',
                payload: {
                    type: 'TASK',
                    taskName,
                },
            },
        ];

        Api.defaultApi()
            .createAutomation(
                userState.data!.workspaceId,
                name,
                selectedDay,
                steps
            )
            .then((response) => {
                console.log('Automation created', response);
                navigate('/automation/configure');
            })
            .finally(() => setSaving(false));
    };

    const nextExecutionDate = (day: number | null) => {
        if (day === null) {
            return null;
        }
        const now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth();

        // If the day has already passed this month, schedule for next month
        if (now.getDate() >= day) {
            month++;
            if (month > 11) {
                // Handle December case
                month = 0;
                year++;
            }
        }

        // Create date and adjust if the day is invalid for the month (e.g., Feb 30)
        const nextDate = new Date(year, month, day);
        if (nextDate.getDate() !== day) {
            nextDate.setDate(0); // Last valid day of the month
        }
        return nextDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    return (
        <Container>
            <div>Trigger</div>
            Select day of the month:
            <div
                style={{
                    marginTop: '32px',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <table style={{ width: '300px', margin: '0 10% 0 10%' }}>
                    {Array.from({ length: 5 }, (_, i) => i + 1).map((week) => (
                        <tr>
                            {Array.from(
                                { length: 7 },
                                (_, i) => i + 1 + (week - 1) * 7
                            )
                                .filter((day) => day <= 31)
                                .map((day) => (
                                    <td style={{ width: '32px' }}>
                                        <Button
                                            size="s"
                                            theme={
                                                selectedDay == day
                                                    ? 'primary'
                                                    : 'plain'
                                            }
                                            key={day}
                                            onClick={() => selectDay(day)}
                                        >
                                            <p>{day}</p>
                                        </Button>
                                    </td>
                                ))}
                        </tr>
                    ))}
                </table>
            </div>
            {selectedDay !== null && (
                <p>
                    This automation will trigger on{' '}
                    {nextExecutionDate(selectedDay)}.
                    <br />
                    In case of shorter months, the last day of the month will be
                    selected.
                </p>
            )}
            <div>
                <TextEditable
                    value={taskName}
                    handleChange={handleTaskNameChange}
                    maxLength={100}
                    placeholder="Task name"
                />
            </div>
            <div style={{ marginTop: '10%' }}>
                <TextEditable
                    value={automationName}
                    handleChange={handleAutomationNameChange}
                    maxLength={100}
                    placeholder={automationNamePlaceholder}
                />
                <Button size="m" theme="primary" onClick={save}>
                    <p>Save</p>
                </Button>
            </div>
        </Container>
    );
};
