import React from 'react';
import { Popover } from '@headlessui/react';

import ContentBox from '../ContentBox';
import { ReactComponent as MenuIcon } from '../../../assets/icons/dots-vertical.svg';
import { ReactComponent as MenuIconHorizontal } from '../../../assets/icons/dots-horizontal.svg';

import styles from './dropdown.module.scss';
import Grid from '../Grid';

type DropdownProps = {
    position?:
        | 'bottomLeft'
        | 'bottomCenter'
        | 'bottomRight'
        | 'topLeft'
        | 'topCenter'
        | 'topRight';
    horizontal?: boolean;
    trigger?: React.ReactNode | React.ReactNode[];
    children: React.ReactNode | React.ReactNode[];
};

const Dropdown: React.FC<DropdownProps> = ({
    position,
    trigger,
    children,
    horizontal,
}: DropdownProps): JSX.Element => {
    return (
        <Popover className={`${styles.wrapper}`}>
            <Popover.Button className={`${styles.button}`}>
                {trigger ? (
                    trigger
                ) : horizontal ? (
                    <MenuIconHorizontal />
                ) : (
                    <MenuIcon />
                )}
            </Popover.Button>
            <Popover.Panel
                className={`${styles.box} ${
                    position ? styles[position] : styles.bottomLeft
                }`}
            >
                <ContentBox padding="s">
                    <Grid columns="1">{children}</Grid>
                </ContentBox>
            </Popover.Panel>
        </Popover>
    );
};

export default Dropdown;
