import Container from '../layout/atoms/Container';
import Button from '../layout/atoms/Button';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Api from '../api';
import { ReactComponent as TrashIcon } from '../assets/icons/trash.svg';
import { ReactComponent as ConfigureIcon } from '../assets/icons/grid.svg';

type Automation = { id: string; name: string; status: 'OFF' | 'ON' };

export const AutomationConfigPage = () => {
    const workspaceData = useSelector((state: any) => state.workspace);
    const [automations, setAutomations] = useState<Automation[]>([]);

    useEffect(() => {
        Api.defaultApi()
            .fetchAutomations()
            .then((response) => {
                console.log(response);
                setAutomations(response as Automation[]);
            });
    }, []);
    
    const deleteAutomation = (id: string) => {
        Api.defaultApi()
            .deleteAutomation(id)
            .then(() => {
                setAutomations(automations.filter((automation) => automation.id !== id));
            });
    };

    return (
        <Container>
            <div>Configure your automations</div>

            <div style={{display: "flex", flexDirection: "column", gap: "12px"}}>
                {automations.length === 0 && (
                    <div>There are no automations configured</div>
                )}
                {automations.length > 0 &&
                    automations.map((automation, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div>{automation.name}</div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    gap: '12px',
                                }}
                            >
                                <div>{automation.status}</div>
                                <Button
                                    size="s"
                                    theme="plain"
                                    /*to={`/automation/${automation.id}/edit`}*/
                                >
                                    <ConfigureIcon /> <p>Edit</p>
                                </Button>
                                <Button size="s" theme="plain" onClick={() => deleteAutomation(automation.id)}>
                                    <TrashIcon /> <p>Delete</p>
                                </Button>
                            </div>
                        </div>
                    ))}
            </div>

            <div>
                <Button
                    size="m"
                    theme="plain"
                    to="/automation/configure/create"
                >
                    <p>+ New automation</p>
                </Button>
            </div>
        </Container>
    );
};
