import React from 'react';
import clsx from 'clsx';
import styles from './Benefit.module.scss';

import { ReactComponent as CheckIcon } from '../../../assets/icons/checkmark-circle.svg';

type BenefitProps = {
    active?: boolean;
    children: React.ReactNode | React.ReactNode[];
};

export const Benefit: React.FC<BenefitProps> = ({
    active = false,
    children,
}: BenefitProps): JSX.Element => {
    const classes = clsx(styles.wrap, {
        [styles.active]: active,
    });

    return (
        <div className={classes}>
            <CheckIcon />
            {children}
        </div>
    );
};
