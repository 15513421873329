import React from 'react';
import clsx from 'clsx';
import styles from './Price.module.scss';

type PriceProps = {
    month: number | string;
    annual?: number | string;
    currency: string;
    period?: 'month' | 'annual';
};

export const Price: React.FC<PriceProps> = ({
    month,
    annual = undefined,
    currency,
    period,
}: PriceProps): JSX.Element => {
    const classes = clsx(styles.wrap, {
        [styles.annual]: period === 'annual',
    });
    const classesFree = clsx(styles.wrap);

    return (
        <div className={annual ? classes : classesFree}>
            <p>
                {currency}
                {month}
            </p>
            {annual && (
                <p>
                    {currency}
                    {annual}
                </p>
            )}
        </div>
    );
};
