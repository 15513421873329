import z from 'zod';

const AutomationStatusShape = z.object({
    id: z.string(),
    name: z.string(),
    status: z.string(),
});

const AutomationShape = z.object({
    id: z.string(),
    name: z.string(),
    status: z.string(),
});

export const shapes = {
    ReadAutomationStatusResponse: z.object({
        automations: z.array(AutomationStatusShape),
    }),
    ReadAutomationsResponse: z.array(AutomationShape),
};

export type ReadAutomationStatusResponse = z.infer<
    typeof shapes.ReadAutomationStatusResponse
>;

export type ReadAutomationsResponse = z.infer<
    typeof shapes.ReadAutomationsResponse
>;
