import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store';
import { changeTheme, LayoutState } from '../../../store/layoutSlice';
import Button from '../Button';
import { ReactComponent as SunIcon } from '../../../assets/icons/sun.svg';
import { ReactComponent as MoonIcon } from '../../../assets/icons/moon.svg';

const ThemeToggle = () => {
    const dispatch = useDispatch<AppDispatch>();
    const layoutState: LayoutState = useSelector((state: any) => state.layout);

    useEffect(() => {
        localStorage.setItem('hustleTheme', layoutState.theme);
    }, [layoutState.theme]);

    useEffect(() => {
        layoutState.theme === 'light'
            ? document.body.classList.remove('dark-theme')
            : document.body.classList.add('dark-theme');
    }, [layoutState.theme]);

    const handleChange = () => {
        dispatch(changeTheme());
    };

    return (
        <Button size="m" onClick={handleChange}>
            {layoutState.theme === 'light' ? <MoonIcon /> : <SunIcon />}
            <p>
                {layoutState.theme === 'light' ? 'Dark theme' : 'Light theme'}
            </p>
        </Button>
    );
};

export default ThemeToggle;
