import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../layout/atoms/Button';
import Container from '../layout/atoms/Container';
import Switch from '../layout/atoms/Switch';
import { useEffect, useState } from 'react';
import ListHeader from '../layout/atoms/ListHeader/ListHeader';
import Grid from '../layout/atoms/Grid';
import ContentBox from '../layout/atoms/ContentBox';
import SubscriptionPlanBox from '../layout/molecules/SubscriptionPlanBox';
import Benefit from '../layout/atoms/Benefit';

import {
    getUserSubscription,
    selectUserSubscription,
} from '../store/userSlice';
import { AppDispatch } from '../store';
import SkeletonLoader from '../layout/atoms/SkeletonLoader';

export const SubscriptionPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const userSubscription = useSelector(selectUserSubscription);
    const [period, setPeriod] = useState<'month' | 'annual'>('month');

    useEffect(() => {
        console.log(userSubscription.status);
        userSubscription.status === 'IDLE' && dispatch(getUserSubscription());
    }, [userSubscription.status]);

    const freeBenefits = () => (
        <Grid columns="1" gap={12}>
            <Benefit active>5 projects</Benefit>
            <Benefit active>Unlimited tasks in project</Benefit>
            <Benefit active>2 automations</Benefit>
            <Benefit>Share and cooperate on projects</Benefit>
            <Benefit>Benefit 5</Benefit>
        </Grid>
    );

    const premiumBenefits = () => (
        <Grid columns="2" gap={12}>
            <Benefit active>Unlimited projects</Benefit>
            <Benefit active>Benefit 1</Benefit>
            <Benefit active>Unlimited tasks in project</Benefit>
            <Benefit active>Benefit 2</Benefit>
            <Benefit active>Unlimited automations</Benefit>
            <Benefit active>Benefit 3</Benefit>
            <Benefit active>Share and cooperate on projects</Benefit>
            <Benefit active>Benefit 4</Benefit>
            <Benefit active={period === 'annual'}>Saving 20%</Benefit>
        </Grid>
    );

    if (
        userSubscription.status === 'IDLE' ||
        userSubscription.status === 'LOADING'
    ) {
        return (
            <Container>
                <ListHeader text="Subscription" />
                <Grid columns="2" gap={16}>
                    <ContentBox>
                        <SubscriptionPlanBox
                            id="001"
                            name={<SkeletonLoader width="70px" height="32px" />}
                            monthPrice="0"
                            pricePeriod={period}
                            current
                            benefits={
                                <SkeletonLoader width="120px" height="24px" />
                            }
                            buttons={
                                <>
                                    <SkeletonLoader
                                        width="100%"
                                        height="54px"
                                    />
                                    <SkeletonLoader
                                        width="100%"
                                        height="54px"
                                    />
                                </>
                            }
                        />
                    </ContentBox>
                </Grid>
            </Container>
        );
    }

    return (
        <Container>
            <Grid columns="1" gap={16}>
                <ListHeader text="Subscription" />
                <Grid columns="2" gap={16}>
                    <ContentBox>
                        <SubscriptionPlanBox
                            id="001"
                            name={
                                <>
                                    <strong>{userSubscription.plan}</strong>
                                </>
                            }
                            monthPrice="0"
                            pricePeriod={period}
                            current
                            benefits={
                                <p>
                                    Valid unitl:{' '}
                                    <strong>
                                        {userSubscription.expirationDate}
                                    </strong>
                                </p>
                            }
                            buttons={
                                <>
                                    <Button size="xxl" theme="primary">
                                        Upgrade to premium
                                    </Button>
                                    <Button size="xxl" theme="plain">
                                        Downgrade to Free
                                    </Button>
                                </>
                            }
                        />
                    </ContentBox>
                </Grid>
                <ListHeader text="Pricing" />
                <Switch
                    name="period"
                    value={period === 'annual'}
                    label="Annualy"
                    leftLabel="Billed monthly"
                    onChange={() =>
                        setPeriod(period === 'month' ? 'annual' : 'month')
                    }
                />
                <Grid columns="5_7" gap={16}>
                    <ContentBox>
                        <SubscriptionPlanBox
                            id="001"
                            name={
                                <>
                                    <strong>Free</strong>
                                </>
                            }
                            monthPrice="0"
                            pricePeriod={period}
                            benefits={freeBenefits()}
                            buttons={
                                <Button size="xxl" theme="plain">
                                    Downgrade to Free
                                </Button>
                            }
                        />
                    </ContentBox>
                    <ContentBox>
                        <SubscriptionPlanBox
                            id="002"
                            name={
                                <>
                                    <strong>Premium</strong>
                                </>
                            }
                            monthPrice="5"
                            annualPrice="4"
                            pricePeriod={period}
                            benefits={premiumBenefits()}
                            buttons={
                                <Button size="xxl" theme="primary">
                                    Upgrade to premium
                                </Button>
                            }
                        />
                    </ContentBox>
                </Grid>
            </Grid>
        </Container>
    );
};
