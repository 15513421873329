import React from 'react';
import clsx from 'clsx';
import styles from './Grid.module.scss';

type GridProps = {
    columns: number | string;
    gap?: number;
    children: React.ReactNode | React.ReactNode[];
};

export const Grid: React.FC<GridProps> = ({
    columns,
    gap = 6,
    children,
}: GridProps): JSX.Element => {
    const classes = clsx(
        styles.wrap,
        styles[`columns-${columns}`],
        styles[`gap-${gap}`]
    );

    return <div className={classes}>{children}</div>;
};
