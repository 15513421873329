import React from 'react';
import clsx from 'clsx';
import styles from './SkeletonLoader.module.scss';

type SkeletonLoaderProps = {
    count?: number;
    width?: string;
    height?: string;
    type?: 'list' | 'grid';
    shape?: 'squared' | 'rounded';
};

export const SkeletonLoader = ({
    count = 1,
    width = '100%',
    height = '60px',
    type = 'list',
    shape = 'squared',
}: SkeletonLoaderProps): JSX.Element => {
    const loaderItems = Array(count).fill(0);

    return (
        <div className={clsx(styles.loaderWrap, styles[type])}>
            {loaderItems.map((_, index) => (
                <div
                    key={index}
                    className={clsx(styles.loader, styles[shape])}
                    style={{ width, height }}
                />
            ))}
        </div>
    );
};
