import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../api';
import { GetUserSubscription, ReadSessionResponse } from '../api/user-model';
import { WorkspaceId } from '../core/model';
import { RootState } from '.';

export interface UserState {
    data: {
        userId: string;
        workspaceId: WorkspaceId;
        userEmail: string;
    } | null;
    status: 'IDLE' | 'LOADING' | 'SUCCEEDED' | 'FAILED';
    error: string | null;
    subscription: {
        plan: string | null;
        isExpired: boolean;
        expirationDate: string | null;
        isTrial: boolean;
        trailEndDate?: string | null;
        status: 'IDLE' | 'LOADING' | 'SUCCEEDED' | 'FAILED';
        error: string | null;
    };
}

const initialState: UserState = {
    data: null,
    status: 'IDLE',
    error: null,
    subscription: {
        plan: null,
        isExpired: false,
        expirationDate: null,
        isTrial: false,
        trailEndDate: null,
        status: 'IDLE',
        error: null,
    },
};

export const readSession = createAsyncThunk(
    'user/readSession',
    async (): Promise<ReadSessionResponse> =>
        await Api.defaultApi().readSession()
);

export const getUserSubscription = createAsyncThunk(
    'user/getUserSubscription',
    async (): Promise<GetUserSubscription> =>
        await Api.defaultApi().getUserSubscription()
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logout: (state: UserState) => {
            state.data = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(readSession.pending, (state) => {
                state.status = 'LOADING';
                state.error = null;
            })
            .addCase(readSession.fulfilled, (state, action) => {
                state.status = 'SUCCEEDED';
                state.data = {
                    userId: action.payload.userId,
                    workspaceId: action.payload.workspaceId as WorkspaceId,
                    userEmail: action.payload.email,
                };
            })
            .addCase(readSession.rejected, (state, action) => {
                state.status = 'FAILED';
                state.error = action.error.message || 'An error occurred';
            })
            .addCase(getUserSubscription.pending, (state) => {
                console.log('getUserSubscription');
                state.subscription.status = 'LOADING';
                state.subscription.error = null;
            })
            .addCase(getUserSubscription.fulfilled, (state, action) => {
                console.log(action.payload);
                state.subscription = {
                    plan: action.payload.plan,
                    isExpired: action.payload.isExpired,
                    expirationDate: action.payload.expirationDate,
                    isTrial: action.payload.isTrial,
                    trailEndDate: action.payload.trailEndDate,
                    status: 'SUCCEEDED',
                    error: null,
                };
            })
            .addCase(getUserSubscription.rejected, (state, action) => {
                state.subscription.status = 'FAILED';
                state.subscription.error =
                    action.error.message || 'An error occurred';
            });
    },
});

export const { logout } = userSlice.actions;

export const selectUserSubscription = (state: RootState) =>
    state.user.subscription;

export default userSlice.reducer;
