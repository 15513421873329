const definition: any = {
    localhost: ['automation', 'subscription'],
    /*Przemek DEV*/ '46zuzCdjUgklxOVLvpi0AV': ['automation', 'subscription'],
    /*Dominik DEV*/ '6FBEtRieXqnD2PNnnIowJ6': ['automation', 'subscription'],
    
    /*Dominik PRD*/ '2i0ErrIS9Fxv9XdRmvqW6L': ['automation'],
};

export const getUserFeatureFlags = (userId: string) => {
    return definition[userId] || [];
};
