import { FeatureFlagService } from './feature-flag-service';

type FeatureFlagProps = {
    feature: string;
    children: React.ReactNode;
};

const FeatureFlag: React.FC<FeatureFlagProps> = ({ feature, children }) => {
    const enabledFeatureFlags = FeatureFlagService.getEnabledFeatureFlags();
    return enabledFeatureFlags.includes(feature) ? <>{children}</> : null;
};

export default FeatureFlag;