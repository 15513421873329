import Container from '../layout/atoms/Container';
import { useEffect, useState } from 'react';
import { ReactComponent as AutomationDoneIcon } from '../assets/icons/automation-done.svg';
import { ReactComponent as AutomationErrorIcon } from '../assets/icons/automation-error.svg';
import { ReactComponent as AutomationIdleIcon } from '../assets/icons/automation-idle.svg';
import { ReactComponent as AutomationWarningIcon } from '../assets/icons/automation-warn.svg';
import Api from '../api';
import Button from '../layout/atoms/Button';
import { ReactComponent as ConfigureIcon } from '../assets/icons/grid.svg';
import ListHeader from '../layout/atoms/ListHeader/ListHeader';

type AutomationStatus = {
    id: string;
    name: string;
    status: 'IDLE' | 'ACTIVE' | 'WARNING' | 'DONE' | 'ERROR';
};

export const AutomationPage = () => {
    const [automations, setAutomations] = useState<AutomationStatus[]>([]);

    useEffect(() => {
        Api.defaultApi()
            .fetchAutomationStatus()
            .then((response) => {
                console.log(response);
                setAutomations(response.automations as AutomationStatus[]);
            });
    }, []);

    const currentMonth = new Date().toLocaleString('default', {
        month: 'long',
    });
    return (
        <Container>
            {automations.length > 0 && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'end',
                    }}
                >
                    <Button size="m" theme="blank" to="/automation/configure">
                        <ConfigureIcon /> <p>Configure</p>
                    </Button>
                </div>
            )}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <div>
                    <ListHeader text={`Month of ${currentMonth}`}></ListHeader>
                    <div>
                        {automations.length === 0 && (
                            <>
                                <div>There are no automations configured</div>
                                <Button
                                    size="m"
                                    theme="blank"
                                    to="/automation/configure"
                                >
                                    <ConfigureIcon /> <p>Configure</p>
                                </Button>
                            </>
                        )}
                        {automations.length > 0 &&
                            automations
                                .sort((a, b) =>
                                    a.status.localeCompare(b.status)
                                )
                                .map((automation, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            margin: '12px',
                                            gap: '12px',
                                            width: '100%',
                                        }}
                                    >
                                        <>
                                            {automation.status === 'DONE' && (
                                                <AutomationDoneIcon
                                                    style={{
                                                        maxHeight: '24px',
                                                    }}
                                                />
                                            )}
                                            {automation.status === 'IDLE' && (
                                                <AutomationIdleIcon
                                                    style={{
                                                        maxHeight: '24px',
                                                    }}
                                                />
                                            )}
                                            {automation.status === 'ERROR' && (
                                                <AutomationErrorIcon
                                                    style={{
                                                        maxHeight: '24px',
                                                    }}
                                                />
                                            )}
                                            {automation.status === 'ACTIVE' && (
                                                <AutomationWarningIcon
                                                    style={{
                                                        maxHeight: '24px',
                                                    }}
                                                />
                                            )}
                                            {automation.status ===
                                                'WARNING' && (
                                                <AutomationWarningIcon
                                                    style={{
                                                        maxHeight: '24px',
                                                    }}
                                                />
                                            )}
                                        </>
                                        <div>{automation.name}</div>
                                    </div>
                                ))}
                    </div>
                </div>
            </div>
        </Container>
    );
};
