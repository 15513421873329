import z from 'zod';

export const shapes = {
    ReadSessionResponse: z.object({
        userId: z.string(),
        workspaceId: z.string(),
        email: z.string().email(),
    }),
    GetUserSubscription: z.object({
        plan: z.string(),
        isExpired: z.boolean(),
        expirationDate: z.string(),
        isTrial: z.boolean(),
        trailEndDate: z.string().optional(),
    }),
};

export type ReadSessionResponse = z.infer<typeof shapes.ReadSessionResponse>;
export type GetUserSubscription = z.infer<typeof shapes.GetUserSubscription>;
