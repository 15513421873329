import clsx from 'clsx';
import styles from './Button.module.scss';
import { Link } from 'react-router';

type ButtonProps = {
    to?: string;
    size?: 's' | 'm' | 'l' | 'xl' | 'xxl';
    theme?: 'blank' | 'plain' | 'primary';
    color?: string;
    disabled?: boolean;
    onClick?: any;
    children: React.ReactNode | React.ReactNode[];
};

export const Button = ({
    to,
    size,
    theme,
    color,
    disabled,
    onClick,
    children,
}: ButtonProps): JSX.Element => {
    const classes = clsx(
        styles.wrap,
        styles[size ? size : 'l'],
        styles[theme ? theme : 'blank']
    );
    const handleOnClick = () => {
        onClick && onClick();
    };

    const content = <>{children && children}</>;

    if (to) {
        return (
            <Link to={to} className={classes}>
                {content}
            </Link>
        );
    }

    return (
        <button className={classes} disabled={disabled} onClick={handleOnClick}>
            {children}
        </button>
    );
};

export default Button;
