import axios, { AxiosInstance } from 'axios';
import { Environment } from '../Environment';
import {
    ReadWorkspaceResponse,
    shapes as workspaceShapes,
} from './workspace-model';
import { AddTaskCommand, shapes as taskShapes, TaskDto } from './task-model';
import {
    AddProjectCommand,
    AddProjectResponse,
    ReadArchivedProjectsResponse,
    RenameProjectCommand,
    shapes as projectShapes,
    UpdateProjectCommand,
} from './project-model';
import {
    PlannedTaskAction,
    ProjectId,
    TaskAction,
    TaskId,
} from '../core/model';
import {
    GetUserSubscription,
    ReadSessionResponse,
    shapes as userShapes,
} from './user-model';
import {
    ReadAutomationsResponse,
    ReadAutomationStatusResponse,
    shapes as automationShapes,
} from './automation-model';
import { FeatureFlagService } from '../feature-flag/feature-flag-service';

export default class Api {
    private http: AxiosInstance;

    constructor() {
        this.http = axios.create({
            baseURL: 'http://localhost:8080/api',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('hustleToken')}`,
                'Content-type': 'application/json',
            },
        });
    }

    public static defaultApi() {
        return new Api();
    }

    public readSession = (): Promise<ReadSessionResponse> => {
        return this.http
            .get(`${Environment.backendUrl}/api/v1/session`)
            .then((result) => {
                const userSession = userShapes.ReadSessionResponse.parse(
                    result.data
                );
                FeatureFlagService.updateUserFlags(userSession.userId);
                return userSession;
            });
    };

    public getUserSubscription = (): Promise<GetUserSubscription> => {
        return this.http
            .get(`${Environment.backendUrl}/api/v1/user/plan`)
            .then((result) =>
                userShapes.GetUserSubscription.parse(result.data)
            );
    };

    public fetchWorkspace = (
        workspaceId: string
    ): Promise<ReadWorkspaceResponse> => {
        return this.http
            .get(`${Environment.backendUrl}/workspaces/${workspaceId}`)
            .then((result) =>
                workspaceShapes.ReadWorkspaceResponse.parse(result.data)
            );
    };

    public fetchArchivedProjects = (
        workspaceId: string
    ): Promise<ReadArchivedProjectsResponse> => {
        return this.http
            .get(
                `${Environment.backendUrl}/api/v1/workspaces/${workspaceId}/archived-projects`
            )
            .then((result) =>
                projectShapes.ReadArchivedProjectsResponse.parse(result.data)
            );
    };

    public addProject = (
        command: AddProjectCommand
    ): Promise<AddProjectResponse> => {
        const parsed = projectShapes.AddProjectCommand.parse(command);
        return this.http
            .post(`${Environment.backendUrl}/api/v1/projects`, parsed)
            .then((result) =>
                projectShapes.AddProjectResponse.parse(result.data)
            );
    };

    public renameProject = (
        projectId: ProjectId,
        command: RenameProjectCommand
    ): Promise<any> => {
        const parsed = projectShapes.RenameProjectCommand.parse(command);
        return this.http
            .put(
                `${Environment.backendUrl}/api/v1/projects/${projectId}/rename`,
                parsed
            )
            .then((result) => {
                console.log(result.data);
                return result.data;
            });
    };

    public addTask = (command: AddTaskCommand): Promise<TaskDto> => {
        const parsed = taskShapes.AddTaskCommand.parse(command);
        return this.http
            .post(`${Environment.backendUrl}/api/v1/tasks`, parsed)
            .then((result) => taskShapes.TaskDto.parse(result.data));
    };

    public deleteTask = (
        taskId: TaskId,
        command: UpdateProjectCommand
    ): Promise<any> => {
        const parsed = projectShapes.UpdateProjectCommand.parse(command);
        return this.http.post(
            `${Environment.backendUrl}/api/v1/tasks/${taskId}/delete`,
            parsed
        );
    };

    public taskAction = (
        taskId: TaskId,
        action: TaskAction,
        command: UpdateProjectCommand
    ): Promise<any> => {
        const parsed = projectShapes.UpdateProjectCommand.parse(command);
        return this.http.put(
            `${
                Environment.backendUrl
            }/api/v1/workspace/tasks/${taskId}/${action.toLowerCase()}`,
            parsed
        );
    };

    public plannedTaskAction = (
        taskId: TaskId,
        action: PlannedTaskAction,
        command: UpdateProjectCommand
    ): Promise<any> => {
        const parsed = projectShapes.UpdateProjectCommand.parse(command);
        return this.http.put(
            `${
                Environment.backendUrl
            }/api/v1/tasks/${taskId}/${action.toLowerCase()}`,
            parsed
        );
    };

    public hideCompletedTasks = (): Promise<any> => {
        return this.http.post(
            `${Environment.backendUrl}/api/v1/workspace/hide-completed`,
            {}
        );
    };

    public clearAllPlannedTasks = (): Promise<any> => {
        return this.http.post(
            `${Environment.backendUrl}/api/v1/workspace/idle-all-planned`,
            {}
        );
    };

    public moveProject = (
        workspaceId: string,
        projectId: string,
        index: number
    ) => {
        return this.http.put(
            `${Environment.backendUrl}/api/v1/workspaces/${workspaceId}/move-project`,
            {
                projectId: projectId,
                index: index,
            }
        );
    };

    public fetchAutomationStatus =
        (): Promise<ReadAutomationStatusResponse> => {
            return this.http
                .get(`${Environment.backendUrl}/api/v1/automation-status`)
                .then((result) =>
                    automationShapes.ReadAutomationStatusResponse.parse(
                        result.data
                    )
                );
        };

    createAutomation(
        workspaceId: string,
        automationName: string | undefined,
        dayOfMonth: number,
        steps: {
            type: 'TASK';
            payload: any;
        }[]
    ) {
        return this.http.post(`${Environment.backendUrl}/api/v1/automations`, {
            workspaceId: workspaceId,
            name: automationName || 'Monthly Automation ' + dayOfMonth,
            trigger: {
                frequency: 'MONTHLY',
                dayOfMonth,
            },
            steps,
        });
    }

    public fetchAutomations = (): Promise<ReadAutomationsResponse> => {
        return this.http
            .get(`${Environment.backendUrl}/api/v1/automations`)
            .then((result) =>
                automationShapes.ReadAutomationsResponse.parse(result.data)
            );
    };

    public deleteAutomation = (
        id: string
    ): Promise<ReadAutomationsResponse> => {
        return this.http.delete(
            `${Environment.backendUrl}/api/v1/automations/${id}`
        );
    };
}
