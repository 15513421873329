import React from 'react';
import styles from './SubscriptionPlanBox.module.scss';
import Price from '../../atoms/Price';
import Grid from '../../atoms/Grid';

type SubscriptionPlanBoxProps = {
    id: string;
    name: React.ReactNode;
    monthPrice: string;
    annualPrice?: string;
    currency?: string;
    pricePeriod: 'month' | 'annual';
    current?: boolean;
    benefits?: React.ReactNode | React.ReactNode[];
    buttons?: React.ReactNode | React.ReactNode[];
};

export const SubscriptionPlanBox: React.FC<SubscriptionPlanBoxProps> = ({
    id,
    name,
    monthPrice,
    annualPrice,
    currency = '$',
    pricePeriod,
    benefits,
    buttons,
    current = false,
}: SubscriptionPlanBoxProps): JSX.Element => {
    return (
        <div className={styles.wrap}>
            <Grid columns="1" gap={16}>
                <Price
                    month={monthPrice}
                    annual={annualPrice}
                    currency={currency}
                    period={pricePeriod}
                />
                <h2 className={styles.header}>{name}</h2>
                {benefits && <div className={styles.benefits}>{benefits}</div>}
            </Grid>
            {buttons && <div className={styles.buttons}>{buttons}</div>}
            {current && <div className={styles.current}>Your current plan</div>}
        </div>
    );
};
